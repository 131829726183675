/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { Link } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import "./subheader.css";
import house from "../../../../Image/house.png";
import "bootstrap/dist/css/bootstrap.min.css";
import main from "../../../../Image/App_logo1.png";
import { MenuBar } from "./Menubar";
import axios from "axios";
import { HOST } from "../../../../container/dr_examination/Redux/DoctorConstant";
import Cookies from "js-cookie";
import AnchorBtn from "../../../../Components/dr.examine/Qbtn1/qbtnAnchor";
import { useHistory } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import ClaimantName from "../../components/subheader/ClaimantName";
import { QuickUserTogglerV2 } from "../extras/QuickUserTogglerV2";
import Axios from "axios";
export function SubHeaderV2({ isSidebarVisible2, setIsSidebarVisible2 }) {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const history = useHistory();
  const [claimantnames, setclaimantnames] = useState([]);
  const userRoleFromCookies = Cookies.get("userRole");

  const [getData, setGetData] = useState();

  const handleData = async () => {
    try {
      const response = await Axios.get(`${HOST}/api/doctor`, { withCredentials: true });
      setGetData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  const userTypeId = Cookies.get("userTypeId");
  const doctorfiltered = getData?.filter((item) => item.id === userTypeId);
  const version = doctorfiltered && doctorfiltered[0]?.version ? doctorfiltered[0].version : "V2";
  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(uiService.config, "subheader.mobile-toggle"),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses("subheader_container", true),
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(uiService.config, "aside.secondary.display"),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, "aside.self.minimize.toggle"),
      extrasSearchDisplay: objectPath.get(uiService.config, "extras.search.display"),
      extrasNotificationsDisplay: objectPath.get(uiService.config, "extras.notifications.display"),
      extrasQuickActionsDisplay: objectPath.get(uiService.config, "extras.quick-actions.display"),
      extrasQuickPanelDisplay: objectPath.get(uiService.config, "extras.quick-panel.display"),
      extrasLanguagesDisplay: objectPath.get(uiService.config, "extras.languages.display"),
      extrasUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs = aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(aside && aside.title && aside.title.length > 0 ? aside.title : header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {}, [subheader]);
  // List of routes where you want to hide the component
  const routesToHide = ["/doctor-app/patientinfo"];
  const routesToHide1 = [`/doctor-app/claimantcompletedform/`];
  const routesToHide3 = [`/claimant/form/`];
  const id1 = location.state?.id;

  const shouldHideComponent = routesToHide.includes(location.pathname);
  const shouldHideComponent1 = location.pathname.startsWith(routesToHide3);
  const isMatchingRoute = location.pathname.startsWith(routesToHide1);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState("");
  const userRole = Cookies.get("userRole");
  const id = location.state?.id;
  useEffect(() => {
    setclaimantnames("");
    if (shouldHideComponent1) {
      const pathParts = window.location.pathname.split("/");
      const id32 = pathParts[pathParts.length - 1];
      const getclaimantList = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${HOST}/api/ClaimantForm/${id32}`, { withCredentials: true });
          setLoading(false);
          setclaimantnames(response.data);
        } catch (error) {
          setLoading(false);
        }
      };
      getclaimantList();
    } else {
      if (id1) {
        const getPatientList = async () => {
          setLoading(true);
          try {
            const response = await axios.get(`${HOST}/api/patient/${id1}`, { withCredentials: true });
            setLoading(false);
            setTableData([response.data]);
          } catch (error) {
            setLoading(false);
          }
        };
        getPatientList();
      }
    }
  }, [shouldHideComponent1,id1]);

  const holdingpatient = async () => {
    try {
      let status;
      let redirectPath;
      if (userRole === "Admin") {
        redirectPath = "/doctor-app/doctorinfo";
      } else {
        if (userRole === "Doctor") {
          status = { status: "L9" };
          redirectPath = "/doctor-app/doctorinfo";
        } else if (userRole === "AccountManager") {
          status = { status: "L9.1" };
          redirectPath = "/doctor-app/frontdesk_session";
        }
        await axios.put(`${HOST}/api/patient/${id}`, status, { withCredentials: true });
        window.location.assign(redirectPath);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const cancelpatient = async () => {
    try {
      let status;
      let redirectPath;
      if (userRole === "Admin") {
        redirectPath = "/doctor-app/frontdesk_session";
      } else {
        if (userRole === "Doctor") {
          status = { status: "dc" };
          redirectPath = "/doctor-app/doctorinfo";
        } else if (userRole === "AccountManager") {
          status = { status: "amc" };
          redirectPath = "/doctor-app/frontdesk_session";
        }
         await axios.put(`${HOST}/api/patient/${id}`, status, { withCredentials: true });
        window.location.assign(redirectPath);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const filteredData = tableData.length > 0 ? tableData.filter((item) => item.id === id1) : "";
  return (
    <div className="your-component">
      {loading && (
        <div className="overlay">
          <Spinner className="loading-spinner" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      <div className="headerbackground">
        <div className={`claimant_card ${userRole === "claimant" ? "logowidth" : ""}`}>
          {shouldHideComponent || shouldHideComponent1 || isMatchingRoute ? (
            <div style={{}}></div>
          ) : (
            <MenuBar setIsSidebarVisible={setIsSidebarVisible2} isSidebarVisible={isSidebarVisible2} />
          )}
          <Link to="/dashboard">
            <img className={isMatchingRoute ? "claimantsidemenu":"claimantsidemenu1"} src={main} style={{ width: "180px", height: "50px" }} />
          </Link>{" "}
          &emsp;
          <div>
            {shouldHideComponent || shouldHideComponent1 ? (
              <ClaimantName filteredData={filteredData} version={version} claimantnames={claimantnames} shouldHideComponent1={shouldHideComponent1} />
            ) : (
              " "
            )}
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          {(userRoleFromCookies === "AccountManager" || userRoleFromCookies === "DoctorAssistant") && (
            <div style={{ display: "flex", alignItems: "center", marginLeft: "200px" }}>
              {userRoleFromCookies === "AccountManager" && !isMatchingRoute && (
                <AnchorBtn
                  className="btn"
                  style={{ background: "white", padding: "9px", color: "black", border: "1px solid black" }}
                  onClick={() => history.push("/doctor-app/assigncard")}
                >
                  Assign
                </AnchorBtn>
              )}
                <AnchorBtn
                  className="btn ml-2"
                  style={{ background: "white", padding: "9px", color: "black", border: "1px solid black", marginRight: "40px" }}
                  onClick={() => history.push("/doctor-app/frontdesk_session")}
                >
                  Session
                </AnchorBtn>
            </div>
          )}
          {(userRole === "Doctor" || userRole === "Admin" || userRole === "Developer") && routesToHide.includes(location.pathname) ? (
            <div>
              <div style={{ marginRight: "20px" }}>
                <span>
                  <Button
                    className="next1"
                    style={{ width: "90px", marginLeft: "10px", fontSize: "14px", fontWeight: "500" }}
                    onClick={userRole === "Admin" || userRole === "Developer" ? null : cancelpatient}
                    disabled={userRole === "Admin" || userRole === "Developer"}
                  >
                    CANCEL
                  </Button>
                </span>
                <span>
                  <Button
                    className="next1"
                    style={{ width: "90px", fontSize: "14px", fontWeight: "500" }}
                    onClick={userRole === "Admin" || userRole === "Developer" ? null : holdingpatient}
                    disabled={userRole === "Admin" || userRole === "Developer"}
                  >
                    HOLDING
                  </Button>
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
           
           <div style={{ marginRight:"-30px",marginTop: "-50px"  }}>
                         {userRoleFromCookies !== "claimant" && (
                           <Link to="/dashboard">
                             <img src={house} width={"60px"} style={{}} className="mobiles" />
                           </Link>
                         )}
                       </div>
           
                       
          {layoutProps.extrasUserDisplay && (
            <div style={{ marginRight: "70px", marginTop: "-40px" }}>
              <QuickUserTogglerV2 />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
