import { useEffect } from "react";
import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';

const PdfWorkerSetup = () => {
    useEffect(() => {
        GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
        console.log(" PDF Worker set to:", GlobalWorkerOptions.workerSrc);
    }, []);

    return null; // No UI, just setting the worker
};

export default PdfWorkerSetup;
